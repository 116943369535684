import React from "react"

import WorkPageLayout from "./Components/WorkPageLayout"
import { Box, Flex, Text } from "../../Components/Base"
import { WORK_ID, WORK } from "../../constant"

import Content from "./Components/Content"

const workId = WORK_ID.GENERATIVE_COVER
const workData = WORK.find(w => w.key === workId)
const ROOT_IMAGE_PATH = `/assets/work/${workId}`

const HeaderSection = () => {
  return (
    <Box>
      <Content.Header>{workData.name}</Content.Header>
      <Content.SubHeader>role: {workData.role}</Content.SubHeader>
      <Flex flexDirection={["column", "row"]} justifyContent={"space-between"}>
        <Content.HeaderParagraph width={"70%"}>
          At Skooldio, dozens of graphic for course materials such as book and
          slide cover need to be created every week. The productivity of the
          design team was dramatically decreased by this overwhelming task.
          Therefore, to solve this problem, my teammates and I decided to spend
          a night finding the solution and that resulted in the graphic
          generator which could generate the unlimited graphic for every
          materials.
        </Content.HeaderParagraph>
        {/* <Content.TextField
          textAlign={"right"}
          size={"small"}
          fields={[
            {
              field: "location",
              value: "Bangkok, Thailand",
            },
            { field: "program", value: "Sale Gallery" },
            { field: "client", value: "Advanced Living" },
            { field: "status", value: "completion 2017" },
            { field: "area", value: "400 sq.m." },
          ]}
        /> */}
      </Flex>
    </Box>
  )
}

const ContentSection = () => {
  return (
    <Content as={"article"}>
      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/thumbnail.jpg`}
          width={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Content.Section>

      <Content.Section>
        {/* <Content.Title>3 designs in one night</Content.Title> */}
        <Content.Paragraph>
          The generator need to have diversity, so we decided to individually
          design and write the script using p5JS library with the same goal
          which is to make it able to generate random graphic while still can
          configure some variables to match each course identity
        </Content.Paragraph>
        {/*
        <Content.Paragraph>
          <Text mb={2}>Every design uses the same format which is</Text>
          <Content.List as={"ol"}>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Find the base geometry or pattern
              </Text>
              - main geometry or pattern which will be configured or randomed
            </Content.ListItem>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Set the configurable variables
              </Text>
              - variables such as color, background, border-style, size, etc.
              that can be configured by designer
            </Content.ListItem>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Set the random variables
              </Text>
              - variables which will be automatically randomed by the generator
            </Content.ListItem>
          </Content.List>
        </Content.Paragraph> */}

        <Content.Image
          src={`/assets/work/${workId}/final-01.png`}
          width={"100%"}
          caption={"final"}
        />
      </Content.Section>

      <Content.Section
        mb={5}
        fullWidth={false}
        left={
          <>
            <Content.Title>Design 1</Content.Title>
            <Content.Paragraph pr={5}>
              This design started from the simple idea to create the circles of
              various sizes, border-styles, and colors which are configurable.
              Then put them together on the random position of the canvas
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`${ROOT_IMAGE_PATH}/version-01-02.gif`}
            width={"100%"}
          />
        }
      />
      {/* <Content.Section>
        <Content.Image
          src={`${ROOT_IMAGE_PATH}/version-01-01.png`}
          width={"100%"}
          caption={"version-01"}
        />
      </Content.Section> */}

      <Content.Section
        mb={5}
        fullWidth={false}
        left={
          <>
            <Content.Title>Design 2</Content.Title>
            <Content.Paragraph pr={5}>
              This design uses the hexagon form, which is the main geometry of
              Skooldio, as the base geometry, then arranged them into row-column
              order. The styles of hexagon such as size, color, and offset can
              be unqiuely configured for each row or column.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`${ROOT_IMAGE_PATH}/version-02-02.gif`}
            width={"100%"}
          />
        }
      />
      {/* <Content.Section>
        <Content.Image
          src={`${ROOT_IMAGE_PATH}/version-02-01.png`}
          width={"100%"}
          caption={"version-02"}
        />
      </Content.Section> */}

      <Content.Section
        mb={5}
        fullWidth={false}
        left={
          <>
            <Content.Title>Design 3</Content.Title>
            <Content.Paragraph pr={5}>
              This design started from creating three simple patterns, which are
              the dot, stripes, and diagonal, then randomly put those pattern on
              the random position of the canvas.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`${ROOT_IMAGE_PATH}/version-03-02.gif`}
            width={"100%"}
          />
        }
      />
      {/* <Content.Section>
        <Content.Image
          src={`${ROOT_IMAGE_PATH}/version-03-01.png`}
          width={"100%"}
          caption={"version-02"}
        />
      </Content.Section> */}

      {/* <Content.Title>
        Combining as a generator for better usability for designers
      </Content.Title>
      <Content.Paragraph>
        After one night, we combine all three design together and create the
        user-friendly generator which allow non-programer to be able to
        generator the graphic for each course on their own.
      </Content.Paragraph>
      <Content.Image
        src={`/assets/work/${workId}/generator.gif`}
        width={"100%"}
        caption={"generator"}
      /> */}

      <Content.Section>
        <Content.Title>The problem is solved!</Content.Title>
        <Content.Paragraph>
          The generator has now been used to create almost a hundred of course
          materials, including book covers, slide covers, and other assets.
          However, there still have plenty room for improvement. Many new
          designs and scripts are in progress and I am also working on building
          the user-friendly UI for the generator.
        </Content.Paragraph>
        <Content.Image
          src={`/assets/work/${workId}/final-03.png`}
          width={"100%"}
          caption={"final"}
        />

        <Content.Image
          src={`/assets/work/${workId}/final-02.gif`}
          width={"100%"}
          caption={"final"}
        />
      </Content.Section>

      <Content.Section>
        <Content.TextField
          fields={[
            {
              field: "Designer | Developer",
              value: [
                "Tadpol Rachatasumrit",
                "Virot Chiraphadhanakul",
                "Thanon Vongprayoon",
              ],
            },
          ]}
        />
      </Content.Section>
    </Content>
  )
}
const Work = () => {
  return (
    <WorkPageLayout
      header={<HeaderSection />}
      fullWidthHeader={true}
      content={<ContentSection />}
      currentWork={workId}
    />
  )
}

export default Work
